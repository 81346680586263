@font-face {
    font-family: 'DK Raleway';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('DK-RALEWAY-REGULAR.eot');
    src: url('DK-RALEWAY-REGULAR.eot?#iefix') format('embedded-opentype'),
    url('DK-RALEWAY-REGULAR.woff2') format('woff2'),
    url('DK-RALEWAY-REGULAR.ttf') format('truetype');
}

@font-face {
    font-family: 'DK Raleway';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('DK-RALEWAY-BOLD.eot');
    src: url('DK-RALEWAY-BOLD.eot?#iefix') format('embedded-opentype'),
    url('DK-RALEWAY-BOLD.woff2') format('woff2'),
    url('DK-RALEWAY-BOLD.ttf') format('truetype');
}
@font-face {
    font-family: 'DK Raleway';
    font-style: normal;
    font-weight: 900;
    font-display: swap;
    src: url('DK-RALEWAY-BLACK.eot');
    src: url('DK-RALEWAY-BLACK.eot?#iefix') format('embedded-opentype'),
    url('DK-RALEWAY-BLACK.woff2') format('woff2'),
    url('DK-RALEWAY-BLACK.ttf') format('truetype');
}