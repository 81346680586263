

.row {
  height: 100%;
}

.MainAppScreen {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  color: black;

}


.react-datepicker-wrapper, input.flatpickr-input {
  width: 100%;
}

.workingPane {
  background-color: #e8e8e8;
  position: relative;
}

.workingPane .controller {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
}

.controlPane {
  background-color: #ffffff;
  padding: 0;
  max-height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: relative;
  z-index: 999999;
}

.header {
  background-color: #ffffff;
  text-transform: uppercase;
  font-weight: 500;
  font-size: .8rem;
  text-align: right;
  padding: 0 1rem;
  border-bottom: 1px solid #E8E8E8;
  margin-bottom: 1rem;
  position: absolute;
  z-index: 9999;
  width: 100%;
}

.form {
  padding: 5rem 1.5rem 2rem  1.5rem;
}

.konvajs-content {
  max-height: 100vh;
}

.helperMessage {
  font-size: .69rem;
  font-weight: normal;
  line-height: 1.07rem;
}

.form-check-input[type="checkbox"] {
  display: inline-block;
  float: unset;
  margin-top: 0;
}

.form-check-label {
  display: inline-block;
  padding-left: 1rem;
  line-height: 1.1;
  width: calc(100% - 1.2em);
}
.form-check-label strong {
  font-weight: bold;
}


.btn-group img { margin-right: .7rem }
.dropdown-toggle { padding-right: 1.7rem }
.dropdown-toggle .glyphicon { margin-left: 20px; margin-right: -40px }
.dropdown-menu>li>a { display: block; border: 0 none; color: #000000; padding: .5rem 1rem; }
.dropdown-menu>li>a img,.dropdown-menu>li>a  .selectImage { max-height: 2.5rem; padding: .1rem .4rem .1rem 0 }
.flowerInput { padding: .3rem; cursor: pointer; max-width: 300px; position: relative; }
.flowerInput.disabled { pointer-events: none; }
a.flowerInput:after { display: inline-block;
  margin-left: .255em;
  content: "×";
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
}
.flowerInput label {
  padding: 0;
  transform: translate(0, -.2rem);
  background: unset;
  display: inline-block !important;
}
.flowerInput span {
  display: inline-block;
  transform: translateY(.45rem);
}
.flowerInput span:first-letter, .flowerInput label:first-letter, .dropdown-menu>li:first-letter, .dropdown-menu li a:first-letter{
  text-transform: capitalize
}
.addFlower {
  display: block;
  text-align: right;
  margin-bottom: 1rem;
  border: 0 none;
  cursor: pointer;
}
.flowerInput img { max-height: 2rem; padding: .1rem .4rem .1rem 0; }
.flowerInput-container { margin-bottom: .7rem !important; }
.dropdown-menu>li>a:hover { background: #f1f9fd } /* $search-blue */
.dropdown-header { display: block;
  padding: .5rem .5rem;
  margin-bottom: 0;
  font-size: .875rem;
  color: #6c757d;
  white-space: nowrap; }
.dropdown-menu { top: 100%; width: 100%; max-height: 10rem; overflow: auto }
button.input-text {text-align: left;}

.screenLock {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #e8e8e8;
  z-index: 99;
  /*background-image: url("images/turndevice.png");*/
  background-repeat: no-repeat;
  background-position: center center;
}

.isMobile .scroll-container {
  max-height: 100%;
  background-color: #aaffaa;
  /*z-index: 99999999;*/
}

a.btn-close {
  text-indent: 2rem;
  overflow: hidden;
}

.stage {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
}

@media (max-width: 767px) {
  .workingPane {
    height: 55vh;
  }
  .controlPane {
    height: 45vh;
  }
}